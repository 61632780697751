import * as React from "react"
import { useEffect } from "react"

import ReactMarkdown from "react-markdown"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Title from "../components/title_cookie"
import Guest from "../components/guest"

const Experts = data => {
  //   console.log(data)
  //   let pageContext, pageData
  let { pageContext } = data
  let { pageData } = pageContext

  // add sceneId
  // let nextPageData
  // nextPageData =
  //   pathname === "/"
  //     ? { slug: "seven_seconds_match", title: "Seven Seconds Match" }
  //     : pageContext.next

  // console.log(pageContext)
  // if (pageContext.next.pageTemplate === "introduction")
  //   nextPageData = { slug: "experts", title: "experts" }
  const prevPageData = pageContext.previous
  let createPrevPage = prevPageData ? (
    <Link className="h3 forced-prev" to={`${prevPageData.slug}`}>
      Next — Statement n°{pageContext.order - 1} <br />
      {prevPageData.title}
    </Link>
  ) : (
    ""
  )
  // const mountedRef = useRef(true)
  import("../js/index")
    .then(m => {
      // if (!mountedRef.current) return null
      m.default()
    })
    .catch(err => {
      console.error("Error during loading module: " + err)
    })

  useEffect(() => {
    return () => {
      const isBrowser = () => typeof window !== "undefined"
      if (!isBrowser()) return
      if (Array.isArray(window.allTimeouts))
        window.allTimeouts.forEach(id => clearTimeout(id))
    }
  }, [])

  return (
    <Layout siteTitle={pageData.title}>
      <Seo
        title={pageData.seo.title}
        keywords={pageData.seo.keywords}
        image={pageData.seo.image}
        description={pageData.seo.description}
      />
      <section className="content">
        <div id="scene1" className="section center-content">
          <Title
            text={pageData.title}
            tinyTilteMobile={pageData.tinyTilteMobile}
          />
        </div>

        {pageData.repeater.map((key, i) => {
          // console.log(key.experts)
          if (key.hasOwnProperty("experts")) {
            return key.experts.map((expert, i) => {
              let idName
              if (i === Math.floor(key.experts.length / 2)) {
                idName = "scene2"
              } else if (i === key.experts.length - 1) {
                idName = "scene3"
              }
              // console.log(expert)

              return (
                <div
                  key={i}
                  id={idName}
                  className="section section center-content__full"
                >
                  <Guest data={expert} />
                </div>
              )
            })
          }
        })}
      </section>
      <div className="content">
        <nav className="center-content pagination">{createPrevPage}</nav>
      </div>
    </Layout>
  )
}

export default Experts
