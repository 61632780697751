import * as React from "react"
import PropTypes from "prop-types"

const Quote = ({ data }) => {
  return (
    <article className="guest">
      <div className="guest-cover">
        <img
          className="guest-profile"
          src={data.picture?.url}
          alt={`picture of ${data.name}`}
        />
        <img
          className="guest-logo"
          src={data.logo?.image?.url}
          alt={data.logo?.image?.altText}
        />
      </div>
      <div className="guest-info">
        <h2 className="guest-title">
          {data.name} <br />
          {data.jobTitle}
        </h2>
        <dl
          className="guest-description"
          dangerouslySetInnerHTML={{ __html: data.character_identity.html }}
        ></dl>
      </div>
    </article>
  )
}

Quote.propTypes = {
  data: PropTypes.object.isRequired,
}
export default Quote
